<template>
  <div class="container with-sidebar">
    <Sidebar />
    <div>
      <h1>{{ $t('situationReport.title', { name: zone.zoneName }) }}</h1>
      <div class="overflow">
        <table class="header-summary">
          <tr>
            <th>{{ $t('situationReport.zoneName') }}:</th>
            <td>{{ zone.zoneName }}</td>

            <th>{{ $t('situationReport.zoneSize') }}:</th>
            <td>{{ zone.zoneSize }} m&#x00B2;</td>

            <th>{{ $t('situationReport.zoneUsage') }}:</th>
            <td>{{ formatZoneUsage(zone.zoneUsage) }}</td>
          </tr>
          <tr>
            <th>{{ $t('situationReport.lastOpened') }}:</th>
            <td>{{ format(zone.accessOpened) }}</td>

            <th>{{ $t('situationReport.lastClosed') }}:</th>
            <td>{{ format(zone.accessClosed) }}</td>

            <th>{{ $t('situationReport.currentState') }}:</th>
            <td>{{ localize(zone.accessState) }}</td>
          </tr>
        </table>
      </div>
      <div v-if="selectedImageReference" class="image-dialog" @click="selectedImageReference = null">
        <div class="close-button">
          <mdicon name="close-outline" />
        </div>
        <img :src="selectedImageReference.fullSizeURL" />
      </div>
      <div class="image-listing">
        <figure class="zone-image" v-for="(imageReference, index) in zone.imageReferences.slice().reverse()" :key="index">
          <img @click="enlargeImage(imageReference)" :src="imageReference.thumbnailURL" />
          <figcaption>{{ format(imageReference.when) }}</figcaption>
        </figure>
      </div>
    </div>
  </div>
</template>

<script>
import { getLanguage, formatDateTime } from '@/library'
import { axiosInstance } from '@/library'
import Sidebar from '@/views/concept/Sidebar.vue';

export default {
  name: 'ZoneDetails',
  components: {
    Sidebar,
  },

  data: () => ({
    zone: {
      imageReferences: []
    },
    selectedImageReference: null
  }),

  beforeCreate () {
    const zoneId = this.$route.params.zoneId
    axiosInstance.get(`${window.VUE_APP_GRANJA}/api/zones/${zoneId}`)
      .then(response => {
        if (response.status === 200) {
          this.zone = response.data
        }
      })
      .catch(console.error)
  },

  methods: {
    formatZoneUsage (value) {
      if (value)
        return this.$t('plants.zones.usageType.' + value.replace(/\s/g, '').toLowerCase())
      else
        return ''
    },

    localize (value) {
      if (value)
        return this.$i18n.t('terms.' + value.toLowerCase())
      else
        return ''
    },

    languageCode (code) {
      return code === getLanguage()
    },

    format (value) {
      return formatDateTime(this.$i18n, value)
    },

    enlargeImage (imageReference) {
      this.selectedImageReference = imageReference
    }
  }
}
</script>

<style scoped>
DIV.overflow {
  overflow-x: scroll;
}

TABLE.header-summary TD {
  padding: 0 1em 0 1em;
}

TH,
TD {
  text-align: left;
  white-space: nowrap;
}

FIGURE.zone-image FIGCAPTION {
  font-weight: unset;
  font-size: unset;
  display: table-caption;
  caption-side: bottom;
  padding-bottom: 1em;
  text-align: center;
  color: black;
}

DIV.image-listing {
  display: flex;
  flex-wrap: wrap;
}

DIV.container IMG {
  max-width: unset;
}

DIV.image-dialog {
  z-index: 10;
  position: absolute;
  left: 5px;
  right: 5px;
  border: 5px solid black;
  background-color: black;
  cursor: pointer;
}

DIV.close-button {
  z-index: 99;
  position: absolute;
  right: 5px;
  background-color: white;
}

DIV.image-dialog IMG {
  position: relative;
  width: 100%;
}
</style>
